import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

import es from 'vuetify/src/locale/es'
Vue.component('my-component', {
  methods: {
    changeLocale () {
      this.$vuetify.lang.current = 'es'
    },
  },
})

export default new Vuetify({
  lang: {
    locales: { es },
    current: 'es',
  },
    theme: {
        // dark: false,
        themes: {
          light: {
            primary: '#3F51B5',
            secondary: '#b0bec5',
            accent: '#8c9eff',
            error: '#FF5252',
            background: '#f5f5f5',
            success: '#44bd32',
            neutro: '#718093',
            otro: '#fbc531'
          },
        },
      },
});
