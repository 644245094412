<template>
    <div>
      <router-view />
    </div>
</template>

<style>
.theme--light.v-application {
    background: #E0E0E0;
    /*color: rgba(0, 0, 0, 0.87);*/
}

.required label::after {
    content: " *";
    color: red;
}
</style>

<script>
  export default {
    data: () => ({ drawer: null }),
  }
</script>