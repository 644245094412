import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from './store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  },
  {
    path: '/',
    name: 'dashboard',
    component: () => import('../views/Dashboard.vue'),
    meta: {requiresAuth: true},
    children: [
      {
        path: '/inicio',
        name: 'home',
        component: () => import('../views/Home.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/Logout.vue'),
        meta: {requiresAuth: true }
      },
      /*consulta*/
      {
        path: '/noticias-publicadas',
        name: 'noticias-publicadas',
        component: () => import('../views/noticia/list-noticia-publicada/list-noticia-publicada.vue'),
        meta: {requiresAuth: true}
      },
      /*usuario*/
      {
        path: '/noticias',
        name: 'noticias',
        component: () => import('../views/noticia/list-noticia/list-noticia.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/noticias/crear',
        name: 'noticias-crear',
        component: () => import('../views/noticia/create-noticia/create-noticia.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/noticias/editar/:id',
        name: 'noticias-editar',
        component: () => import('../views/noticia/edit-noticia/edit-noticia.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/noticias/ver/:id',
        name: 'noticias-ver',
        component: () => import('../views/noticia/show-noticia/show-noticia.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/noticias-rss',
        name: 'noticias-rss',
        component: () => import('../views/noticia/list-noticia-rss/list-noticia-rss.vue'),
        meta: {requiresAuth: true}
      },
      /*administrador*/
      {
        path: '/noticias-autorizaciones',
        name: 'noticias-autorizaciones',
        component: () => import('../views/noticia/list-noticia-autorizacion/list-noticia-autorizacion.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/noticias-revisados',
        name: 'noticias-revisados',
        component: () => import('../views/noticia/list-noticia-revisado/list-noticia-revisado.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/noticias-autorizaciones/revisar/:id',
        name: 'noticias-revisar',
        component: () => import('../views/noticia/show-noticia-revisar/show-noticia-revisar.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/list-portadas',
        name: 'listado-portadas',
        component: () => import('../views/portada/list-portada/list-portada.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/crear-portada',
        name: 'crear-portada',
        component: () => import('../views/portada/create-portada/create-portada.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/edit-portada/:id',
        name: 'edit-portada',
        component: () => import('../views/portada/editar-portada/editar-portada.vue'),
        meta: {requiresAuth: true}
      },
      /*reportes*/
      // {
      //   path: '/reporte-noticias',
      //   name: 'reporte-noticias',
      //   component: () => import('../views/reporte/reporte-noticia/reporte-noticia.vue'),
      //   meta: {requiresAuth: true}
      // },
      {
        path: '/usuarios',
        name: 'usuarios',
        component: () => import('../views/user/list-user/list-user.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/roles',
        name: 'roles',
        component: () => import('../views/rol/list-rol/list-rol.vue'),
        meta: {requiresAuth: true}
      },
      /*clasificadores*/
      {
        path: '/plataformas',
        name: 'plataformas',
        component: () => import('../views/clasificadores/plataforma/list-plataforma/list-plataforma.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/sectores',
        name: 'sectores',
        component: () => import('../views/clasificadores/sector/list-sector/list-sector.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/alcances',
        name: 'alcances',
        component: () => import('../views/clasificadores/alcance/list-alcance/list-alcance.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/valoraciones',
        name: 'valoraciones',
        component: () => import('../views/clasificadores/valoracion/list-valoracion/list-valoracion.vue'),
        meta: {requiresAuth: true}
      },
      {
        path: '/periodicos',
        name: 'periodicos',
        component: () => import('../views/clasificadores/periodico/list-periodico/list-periodico.vue'),
        meta: {requiresAuth: true}
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  if ( to.name !== 'login' && !store.getters.loggedIn){
    router.push({ path: '/login' }).catch(()=>{});
  }
  else{
    next(); 
  }
});

export default router;
