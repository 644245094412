import axios from "axios";

export default axios.create({
  //localhost
  //baseURL: "http://localhost:8000",
  
  //desarrollo
  //baseURL: "http://172.16.160.142",
  
  //produccion
  //baseURL: "http://10.223.101.237",

  baseURL: process.env.VUE_APP_BASE_URL
});